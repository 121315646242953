/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Progressbar 1.10.3
 * http://jqueryui.com/tooltip/
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */
.ui-progressbar {
	height:2em;
	margin-bottom: 20px;
	border:0px;
	overflow: hidden;
	#ui-gradient > .vertical(#f5f5f5, #f9f9f9);
	.ui-border-radius(4px);
	.ui-box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1));
	
	text-align: left;
	.ui-progressbar-value {
		margin: 0px;
		height:100%;
		color: @ui-white; /*this can be removed if ui-widget-header is blue*/
		background-color: #428BCA;
		.ui-box-sizing(border-box);
		.ui-transition( width 0.6s ease);
	}
	.ui-progressbar-overlay{
		#ui-gradient > .vertical( @ui-link-color, @ui-link-hover-color );	// FIXME - Verify that this matches the actual CSS outcome.
		background-size: 40px 40px;
		.ui-animation( progress-bar-stripes 2s linear infinite);
	}		
}

.ui-progressbar-indeterminate .ui-progressbar-value {
	background-image: none;
}