/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI spinner 1.10.3
 * http://docs.jquery.com/UI/Menu#theming
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */

.ui-spinner {
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 0;
	vertical-align: middle;
}

.ui-spinner-input {
	border: none;
	background: none;
	padding: 0;
	margin: .2em 22px 0.2em 0.4em;
	vertical-align: middle;
}

.ui-spinner-button {
	width: 16px;
	height: 50%;
	font-size: .5em;
	padding: 0;
	margin: 0;
	text-align: center;
	position: absolute;
	cursor: default;
	display: block;
	overflow: hidden;
	right: 0;
}

/* more specificity required here to overide default borders */
.ui-spinner {
	a.ui-spinner-button {
		border-top: none;
		border-bottom: none;
		border-right: none;
	}
	/* vertical centre icon */
	.ui-icon {
		position: absolute;
		margin-top: -8px;
		top: 50%;
		left: 0;
	}
	/* need to fix icons sprite */
	.ui-icon-triangle-1-s {
		background-position: -65px -16px;
	}
}
	
.ui-spinner-up {
	top: 0;
}

.ui-spinner-down {
	bottom: 0;
}
