/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Datepicker 1.10.3
 * http://docs.jquery.com/UI/Datepicker#theming
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */
 .ui-datepicker { 
 	width: 17em; 
 	padding: .2em .2em 0; 
 	display: none; 
 	.ui-datepicker-header { 
 		position:relative; 
 		padding:.2em 0; 
 		border:0;
 		font-weight: bold;
 		width: 100%;
 		padding: 4px 0;
 		background-color: @table-bg-hover;
 		color: @ui-gray;
 	}
 	.ui-datepicker-prev,
 	.ui-datepicker-next { 
 		position:absolute; 
 		top: 2px; 
 		width: 1.8em; 
 		height: 1.8em; 
 	}

 	.ui-datepicker-prev-hover,
 	.ui-datepicker-next-hover { 
 		/*top: 1px;*/ 
 	}
 	.ui-datepicker-prev { left:2px; }
 	.ui-datepicker-next { right:2px; }

 .ui-datepicker-prev-hover { /*left:1px;*/ }
.ui-datepicker-next-hover { /*right:1px;*/ }

.ui-datepicker-prev span, 
.ui-datepicker-next span { 
	display: block; 
	position: absolute; 
	left: 50%; 
	margin-left: -8px; 
	top: 50%; 
	margin-top: -8px;  
}
.ui-datepicker-title {
	margin: 0 2.3em;
	line-height: 1.8em;
	text-align: center;
	select { 
		font-size:1em; 
		margin:1px 0; 
	}
}
select.ui-datepicker-month-year {
	width: 100%;
}
select.ui-datepicker-month, select.ui-datepicker-year {
	width: 49%;
}
table {
	width: 100%; 
	font-size: 1em; 
	border-collapse: collapse; 
	margin:0 0 .4em; 
}
th { 
	padding: .7em .3em; 
	text-align: center; 
	font-weight: bold; 
	border: 0;  
}
td {
	border: 0; 
	padding: 1px; 
	span, a { 
		display: block; 
		padding: .2em; 
		text-align: right; 
		text-decoration: none; 
	}
}
.ui-datepicker-buttonpane { 
	background-image: none; 
	margin: .7em 0 0 0; 
	padding:0 .2em; 
	border-left: 0; 
	border-right: 0; 
	border-bottom: 0; 
	button { 
		float: right; 
		margin: .5em .2em .4em; 
		cursor: pointer; 
		padding: .2em .6em .3em .6em; 
		width:auto; 
		overflow:visible;
		.ui-datepicker-current {
			float:left; 
		}
	}
}
}
/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi { width:auto; }
.ui-datepicker-multi .ui-datepicker-group { float:left; }
.ui-datepicker-multi .ui-datepicker-group table { 
	width:95%; 
	margin:0 auto .4em; 
}
.ui-datepicker-multi-2 .ui-datepicker-group { width:50%; }
.ui-datepicker-multi-3 .ui-datepicker-group { width:33.3%; }
.ui-datepicker-multi-4 .ui-datepicker-group { width:25%; }
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width:0; }
.ui-datepicker-multi .ui-datepicker-buttonpane { clear:left; }
.ui-datepicker-row-break { 
	clear:both; 
	width:100%; 
	font-size:0em; 
}

/* RTL support */
.ui-datepicker-rtl {
	direction: rtl;
	.ui-datepicker-prev { 
		right: 2px; 
		left: auto;
		&:hover {
			right: 1px; 
			left: auto; 
		}
	}
	.ui-datepicker-next { 
		left: 2px; 
		right: auto;
		&:hover {
			left: 1px; 
			right: auto;
		}
	}
	.ui-datepicker-buttonpane {
		clear:right; 
		button {
			float: left;
			.ui-datepicker-current {
				float:right;
			}
		}
	}
	.ui-datepicker-group {
		float:right;
	}
	.ui-datepicker-group-last .ui-datepicker-header { 
		border-right-width:0; 
		border-left-width:1px; 
	}
	.ui-datepicker-group-middle .ui-datepicker-header { 
		border-right-width:0; 
		border-left-width:1px; 
	}
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
	display: none; /*sorry for IE5*/
	//display/**/: block; /*sorry for IE5*/
	position: absolute; /*must have*/
	z-index: -1; /*must have*/
	filter: mask(); /*must have*/
	top: -4px; /*must have*/
	left: -4px; /*must have*/
	width: 200px; /*must have*/
	height: 200px; /*must have*/
}

.ui-datepicker th{
	font-weight: bold;
	color: @ui-gray;
}

.ui-datepicker-today {
	a{
		background-color: @ui-link-color;
		cursor: pointer;
		padding: 0 4px;
		margin-bottom:0px;
		&:hover{
			background-color: @ui-gray;
			color: @ui-white;
		}
	}
}


.ui-datepicker td {
	a{
		margin-bottom:0px;
		border:0px;
	}

	&:hover{
		color:@ui-white;
	}

	.ui-state-default {
		border: 0;
		background:none;
		margin-bottom: 0;
		padding: 5px;
		color: @ui-gray;
		text-align: center;
		filter: none;
	}
	
	.ui-state-highlight{
		color: @ui-white;
		background: fade(@brand-primary, 80%);
		border-color: #46b8da;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
		border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
		.ui-border-radius(0px);
	}
	
	.ui-state-active {
		color:@gray-dark;
		background:@ui-gray-lighter;
		border-color: #adadad;
		margin-bottom:0px;
		font-size:normal;
		text-shadow: 0px;
		border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
		.ui-border-radius( 0px );
	}
	.ui-state-hover{
		color:@ui-white;
		background:@label-primary-bg;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
		border-color: #357ebd;
		border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
		.ui-border-radius( 0px );
	}
}