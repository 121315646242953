/*
 * jQuery UI Bootstrap v1.0 Alpha (Mixins)
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT.
 */

// Border Radius
#ui-border-radius {
	.topLeft ( @radius: 4px ) {
		-moz-border-radius-topleft: @radius;
		-webkit-border-top-left-radius: @radius;
		-khtml-border-top-left-radius: @radius;
		border-top-left-radius: @radius;
	}
	.topRight ( @radius: 4px ) {
		-moz-border-radius-topright: @radius;
		-webkit-border-top-right-radius: @radius;
		-khtml-border-top-right-radius: @radius;
		border-top-right-radius: @radius;
	}
	.bottomLeft ( @radius: 4px ) {
		-moz-border-radius-bottomleft: @radius;
		-webkit-border-bottom-left-radius: @radius;
		-khtml-border-bottom-left-radius: @radius;
		border-bottom-left-radius: @radius;
	}
	.bottomRight ( @radius: 4px ) {
		-moz-border-radius-bottomright: @radius;
		-webkit-border-bottom-right-radius: @radius;
		-khtml-border-bottom-right-radius: @radius;
		border-bottom-right-radius: @radius;
	}
	
	.border( @topLeft:6px,  @topRight:6px, @bottomRight:6px, @bottomLeft:6px ) {
		-webkit-border-radius: @topLeft @topRight @bottomRight @bottomLeft;
		-moz-border-radius: @topLeft @topRight @bottomRight @bottomLeft;
		border-radius: @topLeft @topRight @bottomRight @bottomLeft;
	}
	
}

.ui-border-radius( @radius ){
	#ui-border-radius  > .topLeft ( @radius );
	#ui-border-radius  > .topRight ( @radius );
	#ui-border-radius  > .bottomLeft ( @radius );
	#ui-border-radius  > .bottomRight ( @radius );
}

#ui-gradient {
	.vertical(@startColor, @endColor){
		#gradient > .vertical(@startColor, @endColor);
	}

	.vertical-three-colors(@startColor, @midColor, @colorStop, @endColor) {
		#gradient > .vertical-three-colors(@startColor, @midColor, @colorStop, @endColor);
	}
}

// Transitions
.ui-transition( @transition ){
	.transition( @transition );
}

.ui-transition( @arg1, @arg2 ) {
	-webkit-transition: @arg1, @arg2;
	-moz-transition: @arg1, @arg2;
	-ms-transition: @arg1, @arg2;
	-o-transition: @arg1, @arg2;
	transition: @arg1, @arg2;
}

// Drop shadows
.ui-box-shadow( @shadow ){
	.box-shadow( @shadow );
}

.ui-box-shadow( @arg1, @arg2 ){
	-webkit-box-shadow: @arg1, @arg2;
	-moz-box-shadow: @arg1, @arg2;
	box-shadow: @arg1, @arg2;
}

.ui-button-variant(@color; @background; @border) {
	.button-variant(@color; @background; @border);
}

.ui-button-size (@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large){
	.button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}

.ui-btn-lg{
	.ui-button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
}
.ui-btn-xs{
	.ui-button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}
.ui-btn-sm{
    padding: 1px 5px;
}

.ui-opacity(@opacity) {
	.opacity(@opacity); // removed "/100" so that same values can be used via .opacity and .ui-opacity mixins.
}

.ui-reset-filter(){
	.reset-filter();
}

.ui-box-sizing(@boxmodel) {
	.box-sizing(@boxmodel);
}

.ui-user-select(@select) {
	.user-select(@select);
}

.ui-tab-focus() {
	.tab-focus();
}

.ui-animation(@anim) {
  -webkit-animation: @anim;
     -moz-animation: @anim;
      -ms-animation: @anim;
       -o-animation: @anim;
          animation: @anim;
}