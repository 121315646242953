/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI Tabs 1.10.3
 * http://docs.jquery.com/UI/Tabs#theming
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter, Inc.
 * Created the LESS version by @dharapvj
 * Released under MIT
 */
.ui-tabs { 
	position: relative; 	/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
	border: 0; 
	.ui-border-radius(0);
	.ui-tabs-nav { 
		margin-bottom: 5px;
		border: solid #ddd;
		border-width: 0 0 1px 0;
		.ui-border-radius( 0 );
		background: none;
	}
	.ui-tabs-nav li { 
		position: relative;
		top: 0;
		float: left;
		margin-right: 2px;
		margin-bottom: -1px;
		border: 0;
		list-style: none;
		white-space: nowrap;
		background: none;
	}
}

.ui-tabs-nav .ui-state-default{
	border: 0;
	.ui-box-shadow( none );
}
.ui-tabs {
	.ui-tabs-nav{
		li a {
			float: left;
			border: 1px solid @ui-white;
			border-bottom: 1px solid #ddd;
			#ui-border-radius > .border( 4px, 4px, 0, 0 );
			padding: 8px 12px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
			color: #0069D6;
			background: none;
			&:hover{
				border: 1px solid whiteSmoke;
				border-bottom: 1px solid #ddd;
				background-color: whiteSmoke;
			}
		}
		li.ui-tabs-active a{
			border: 1px solid #ddd;
			border-bottom: 1px solid #fff;
			background-color: #fff;
			color: #555;
		}
		li.ui-tabs-active:hover{
			background: #fff;
			cursor: text;
		}
		li.ui-tabs-active a,
		li.ui-state-disabled a,
		li.ui-tabs-loading a {
			cursor: text;
		}
	}
	/* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
	.ui-tabs-panel {
		display: block;
		margin: 1em 0;
		border: 0;
		.ui-border-radius(0);
		padding: 1px 0;
		background: none;
	}
	.ui-tabs-hide {
		display: none !important;
	}
	.ui-tabs-nav li {
		filter:none;
	}
}
