/* Misc visuals
----------------------------------*/

/* Corner radius */

.ui-corner-all {
	border-radius: @border-radius-base;
}

.ui-corner-top, .ui-corner-left, .ui-corner-tl {
	border-top-left-radius: @border-radius-base;
}

.ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-top-right-radius: @border-radius-base;
}

.ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
	border-bottom-left-radius: @border-radius-base;
}

.ui-corner-bottom, .ui-corner-right, .ui-corner-br {
	border-bottom-right-radius: @border-radius-base;
}


/* Overlays */

.ui-widget-overlay {
	background: @gray-light url("@{ui-image-dir}/ui-bg_flat_0_@{ui-gray-light-num}_40x100.png") 50% 50% repeat-x;
	.ui-opacity(.30);
}

.ui-widget-shadow {
	margin: -8px 0 0 -8px;
	padding: 8px;
	background: @gray-light url("@{ui-image-dir}/ui-bg_flat_0_@{ui-gray-light-num}_40x100.png") 50% 50% repeat-x;
	.ui-opacity(.30);
	border-radius: 8px ;
}


/*** Input field styling from Bootstrap **/

/*
input, textarea {
	.ui-transition(~"border linear 0.2s, box-shadow linear 0.2s");
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input:focus, textarea:focus {
	outline: 0;
	border-color: rgba(82, 168, 236, 0.8);
	.ui-box-shadow(~"inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6)");
}
input[type=file]:focus, input[type=checkbox]:focus, select:focus {
	.ui-box-shadow (none);
	outline: 1px dotted #666;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
input[type="password"],
.ui-autocomplete-input,
textarea,
.uneditable-input {
	display: inline-block;
	padding: 4px;
	font-size: 13px;
	line-height: 18px;
	color: #555555;
	border: 1px solid #ccc;
	border-radius: 3px;
}

input[type="search"] {
  -webkit-appearance: textfield;
  .ui-box-sizing( content-box);
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
*/